import Swiper from 'swiper';
import $ from 'jquery';
import {
  EcommerceItemListView,
  EcommerceSliderElement,
  GoogleTagEvent,
  GoogleTagEventNames,
} from '@/typings/google-tag-manager';
import isInViewport from '@/js/common/isInViewport';
import { GoogleAnalyticsItem, GoogleAnalyticsProductDetail } from '@/typings/google-analytics';
const previouslyEmitted = {};
export function emitViewSliderElement (landingOrLayoutTitle: string, swiper: Swiper, slideIndex: number ): void {
  // return;

  if (typeof swiper.el === 'undefined' || typeof slideIndex === 'undefined') {
    console.error('undefined index or element for slider');
    return;
  }

  if (typeof swiper.slides[slideIndex] === 'undefined') {
    console.error(`selected slide ${slideIndex }does not exist in swiper ${swiper.$el.attr('id')}`);
    // console.debug(swiper.slides);
    return;
  }

  let slideLinkTitle = $(swiper.slides[slideIndex]).attr('title') as string;
  if (!slideLinkTitle) {
    slideLinkTitle = $(swiper.slides[slideIndex]).attr('href') as string;
  }

  if (false === isInViewport(swiper.el)) {
    return;
  }

  if (isPreviouslyEmitted(`${swiper.el.id}-${slideIndex}`, slideIndex)) {
    return;
  }

  const items = [];
  let eventName: GoogleTagEventNames = 'view_slider_element';

  if (swiper.el.classList.contains('swiper-products-container')) {
    eventName = 'view_item_list';
    window.pushDataLayer({
      event: eventName,
      ecommerce: {
        items: [] as GoogleAnalyticsItem[],
        detail: {
          products: [] as GoogleAnalyticsProductDetail[],
        },
        products: [],
        item_list_id: '',
        item_list_name: landingOrLayoutTitle, // czy tutaj nie powinna isc nazwa swipera
      } as EcommerceItemListView,
    } as GoogleTagEvent,
    )
  } else {
    window.pushDataLayer({
      event: eventName,
      ecommerce: {
        creative_name: slideLinkTitle,
        promotion_name: landingOrLayoutTitle,
        index: slideIndex + 1,
      } as EcommerceSliderElement,
    } as GoogleTagEvent,
    )
  }
  // @todo zamienic na range, wysylac w itemsach pozbierane slidy widoczne
  setPreviouslyEmitted(`${swiper.el.id}-${slideIndex}`, [slideIndex]);
}

function isPreviouslyEmitted (componentTitle: string, index: number): boolean {
  return getPreviouslyEmitted(componentTitle) !== undefined && getPreviouslyEmitted(componentTitle).includes(index);
}

function setPreviouslyEmitted (componentTitle: string, indexes: Array<number>): void {
  (previouslyEmitted[componentTitle as keyof typeof previouslyEmitted] as Array<number>) = indexes;
}

function getPreviouslyEmitted (componentTitle: string): Array<number> {
  return previouslyEmitted[componentTitle as keyof typeof previouslyEmitted] as Array<number>
}


