import Swiper from 'swiper11';
import $ from 'jquery';
import { Site } from './site';
import { NavigationOptions } from 'swiper11/types/modules/navigation';
import { Autoplay, Navigation, A11y,  } from 'swiper11/modules';
import { addClickEventsToSlider, addViewEventsToSlider } from '@/js/swiper/swiper-events-generator';
import {SwiperOptions} from 'swiper11/types/swiper-options';

$(function () {
  const slidesCount = $('#mainSwiper').find('.swiper-slide').length;

  let navigation;
  if (Site.isDesktop && slidesCount > 1) {
    navigation = {
      enabled: true,
      nextEl: '#mainSwiper .swiper-button-next',
      prevEl: '#mainSwiper .swiper-button-prev',
    } as NavigationOptions;
  } else {
    navigation = {
      enabled: false,
      nextEl: undefined,
      prevEl: undefined,
    } as NavigationOptions;
  }
  const mainSwiperOpts: SwiperOptions = {
    modules: [
      Autoplay,
      Navigation,
      A11y,
    ],
    autoplay: {
      delay: 3000,
    },
    speed: 1100,
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      1200: {
        slidesPerView: 1,
        spaceBetween: 16,
      },
    },
    freeMode: true,
    loop: slidesCount > 1,
    autoHeight: false,
    navigation: navigation,
  };

  const swiper = new Swiper('#mainSwiper', mainSwiperOpts);
  const swiperName = 'Strona główna';

  addViewEventsToSlider(swiperName, swiper);
  addClickEventsToSlider(swiperName, swiper);
});

