import Swiper from 'swiper';
import { PaginationOptions } from 'swiper/types/components/pagination';
import { NavigationOptions } from 'swiper/types/components/navigation';

export default function crossSellSwiper (): void {
  const paginationOpts: PaginationOptions = {
    el: '',
  };
  const navigationOpts: NavigationOptions = {
    nextEl: '#crossSellSwiper .swiper-button-next',
    prevEl: '#crossSellSwiper .swiper-button-prev',
  };
  new Swiper('#crossSellSwiper', {
    direction: 'horizontal',
    spaceBetween: 10,
    speed: 1100,
    freeMode: true,
    scrollbar: false,
    observer: true,
    observeParents: true,
    pagination: paginationOpts,
    navigation: navigationOpts,
    preloadImages: false,
    lazy: true,
    loop: false,
    watchSlidesVisibility: true,
    breakpoints: {
      0: {
        slidesPerView: 1.5,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      600: {
        slidesPerView: 2.5,
        slidesPerGroup: 2,
        spaceBetween: 0,
      },
      950: {
        slidesPerView: 3.5,
        slidesPerGroup: 3,
        spaceBetween: 0,
      },
      1200: {
        slidesPerView: 4.5,
        slidesPerGroup: 4,
        spaceBetween: 0,
      },
    },
  });
}
