import {
  emitViewSliderElement,
} from '@/js/swiper/swiper-event-emmiter';
import Swiper from 'swiper';
import $ from 'jquery';
import { EcommerceSliderElement } from '@/typings/google-tag-manager';
import isInViewport from '@/js/common/isInViewport';
import getActiveIndex from '@/js/swiper/getActiveIndex';
import _ from 'lodash';

export function addViewEventsToSlider (landingOrLayoutTitle: string, swiper: Swiper): void {
  let indexRight = getActiveIndex(swiper);

  if (swiper.el.parentElement && swiper.el.parentElement.classList.contains('tab-pane')) {
    $('.cms-component button[data-toggle="tab"]').on('shown.bs.tab', function (event) {
      const tabSwiper = $(`${$(event.target).data('target')} #${swiper.el.id}`);
      if (tabSwiper.attr('id') === swiper.el.id) {
        emitViewSliderElement(landingOrLayoutTitle, swiper, getActiveIndex(swiper));
      }
    })
  }

  swiper.on('slideChange', function (e: Swiper) {
    const previousIndexRight = _.clone(indexRight);
    indexRight = getActiveIndex(swiper);
    if (previousIndexRight < indexRight) {
      for (let i = previousIndexRight; i <= indexRight; i++) {
        emitViewSliderElement(landingOrLayoutTitle, e, i);
      }
    }
  });

  $(document).on('scroll', function () {
    if ((typeof swiper.el !== 'object') ||
      (swiper.el instanceof HTMLDivElement && !isInViewport(swiper.el))) {
      return;
    }

    for (let i = 0; i <= getActiveIndex(swiper); i++) {
      emitViewSliderElement(landingOrLayoutTitle, swiper, i);
    }
  })
}

export function addClickEventsToSlider (landingPageTitle: string, swiper: Swiper): void {
  swiper.on('click', function (e: Swiper, event) {
    if (event.target instanceof HTMLButtonElement) {
      // console.debug(event.target);
      return;
    }

    const clickedSlide = $(e.clickedSlide);
    let elementTitle = clickedSlide.attr('title') as string;
    if (!elementTitle) {
      elementTitle = clickedSlide.attr('href') as string;
    }

    window.pushDataLayer({
      event: 'click_slider_element',
      ecommerce: {
        creative_name: elementTitle,
        promotion_name: landingPageTitle,
        index: swiper.clickedIndex + 1,
      } as EcommerceSliderElement,
    });
  });
}
