
export default function (
  place: string,
  element: HTMLElement,
): void {
  element.querySelectorAll('.suggestion').forEach((suggestion, index) => {
    const label = suggestion.querySelector('.sg-title')?.textContent?.trim();
    label && suggestion.querySelectorAll('a').forEach(
      (anchorElement) => anchorElement.addEventListener(
        'click',
        () => window.pushDataLayer({
          event: 'ux_events',
          event_place: place,
          event_category: 'click_box',
          event_label: label,
          index: index + 1,
        }),
      ),
    );
  });
}
