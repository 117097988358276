import { Alert } from '../alert';
import { Site } from '../site';

import QuantityUpdateResponse from '../interface/QuantityUpdateResponse';
import CartItemsUpdateRequest from '../interface/CartItemsUpdateRequest';
import $ from 'jquery';
import crossSellSwiper from '../crossSellSwiper';
import Route from '@/js/utils/route';
import { UICartEventItem } from '@/ui-kit/js/types/event';
import { ADDED_FROM_PRODUCT_VIEW } from '../const/constants';
import { UICartUpdateEventSource } from '@/ui/composable/useCart';
import * as Cookies from 'es-cookie';

export default function changeQuantityModal (
  items: CartItemsUpdateRequest,
): void {
  if (Site.siteType === 'cart') {
    return;
  }

  const addedToCartModal = $('#addedToCartModal');
  // let isValidQty = true;
  // items.items.forEach(item => {
  //     if (item.quantity > 100) {
  //         isValidQty = false;
  //     }
  // });
  //
  // if (!isValidQty) {
  //     Alert.replaceMessage('Wybrana ilość nie jest dostępna');
  //     Alert.show();
  //
  //     return;
  // }

  void $.ajax({
    type: 'POST',
    url: Route.get('update-quantity'),
    headers: {'X-XSRF-TOKEN': Cookies.get('XSRF_TOKEN') ?? '' },
    data: {
      items,
      pageType: items.pageType,
      buttonType: 'Cart Modal Quantity Button',
      buttonCopy: items.buttonCopy,
    },
    success (response: QuantityUpdateResponse) {
      $('.mobile-cart-count').html(`(${response.cartCount})`);
      $('.cart-count').html(`(${response.cartCount})`);
      addedToCartModal.html(response.modalContent);
      crossSellSwiper();
      response.cartTotal &&
      response.cartTotal >= 0 &&
      document.dispatchEvent(new CustomEvent('cart_update', {
        detail: {
          cartTotal: response.cartTotal,
          cartCount: response.cartCount,
          source: UICartUpdateEventSource.Cart,
        },
      }));
    },
    error (response: JQueryXHR) {
      console.error(response.responseText);
      Alert.replaceMessage('Wystąpił błąd podczas zmiany ilości sztuk.');
      Alert.show();
    },
  });
}
