import Swiper from 'swiper';

export default function getActiveIndex (swiper: Swiper): number {
  const slidesPerView: number = Number(swiper.params.slidesPerView) ?? 1;
  let indexRight = swiper.realIndex + slidesPerView;
  if (swiper.slides.length > 0 && swiper.slides.length < slidesPerView) {
    indexRight = swiper.slides.length;
  } else if (indexRight > swiper.slides.length) {
    indexRight = swiper.slides.length;
  }

  return indexRight - 1;
}
