import quantityDown from './AjaxCart/quantityDown';
import quantityUp from './AjaxCart/quantityUp';
import addToCart from './AjaxCart/addToCart';
import changeQuantityModal from './AjaxCart/changeQuantityModal';
import removeFromCartModal from './AjaxCart/removeFromCartModal';
import $ from 'jquery';
import CartItemsUpdateRequest from './interface/CartItemsUpdateRequest';
import CartItemsAddRequest from './interface/CartItemsAddRequest';
import { ADDED_FROM_PRODUCT_VIEW,
  ADDED_FROM_PRODUCT_VIEW_SET,
  ADDED_FROM_SEARCH_RESULT } from './const/constants';
import { GoogleAnalyticsProductDetail } from '@/typings/google-analytics';
import { UICartUpdateProductEvent } from '@/ui-kit/js/types/event';

interface ItemInCart {
  itemId: string;
  productId: number;
  quantity: number;
}

// Added to cart Modal button control
$(document).on('click', '#cartQuantityDownBtn', function (): void {
  const items: Array<ItemInCart> = [];
  const pageType: string = $('body').data('pageType') as string;
  $(`.cart-item-container`).each(function (index, item) {
    items.push({
      itemId: $(item).attr('data-item-id') as string,
      productId: Number($(item).attr('data-product-id')),
      quantity: Number($(item).attr('data-quantity')),
    });
  });
  const itemRequest: CartItemsUpdateRequest = {
    items: items,
    pageType: pageType,
    buttonCopy: $(this).text(),
    buttonType: 'Cart Modal Quantity Button',
  };
  const itemId: string = String($(this).attr('data-item-id')) || '';
  const productId: number = Number($(this).attr('data-product-id')) || 0;
  if (itemId.length > 0) {
    quantityDown(itemId, productId, itemRequest);
  }
});

// Added to cart Modal button control
$(document).on('click', '#cartQuantityUpBtn', function (): void {
  const items: Array<ItemInCart> = [];
  const pageType: string = $('body').data('pageType') as string;

  $(`.cart-item-container`).each(function (index, item) {
    items.push({
      itemId: $(item).attr('data-item-id') as string,
      productId: Number($(item).attr('data-product-id')),
      quantity: Number($(item).attr('data-quantity')),
    });
  });
  const itemRequest: CartItemsUpdateRequest = {
    items: items,
    buttonCopy: $(this).text(),
    buttonType: 'Cart Modal Quantity Button',
    pageType: pageType,
  };
  const itemId: string = String($(this).attr('data-item-id')) || '';
  const productId: number = Number($(this).attr('data-product-id')) || 0;
  if (itemId) {
    quantityUp(itemId, productId, itemRequest);
  }
});
// Added to cart modal quantity input.
let timeout: number | null = null;
$(document).on('keyup', '#addedToCartModal input[name=quantity]', function () {
  if (timeout !== null) {
    clearTimeout(timeout);
  }
  timeout = window.setTimeout(() => {
    const newQuantity = parseInt($(this).val() as string);
    const productId: number = Number($(this).attr('data-product-id')) || 0;
    const items: Array<ItemInCart> = [];
    let pageType = 'Cart View';
    $(`.cart-item-container`).each(function (index, item) {
      pageType = $('body').data('pageType') as string;
      items.push({
        itemId: $(item).attr('data-item-id') as string,
        productId: Number($(item).attr('data-product-id')),
        quantity:
          Number($(item).attr('data-product-id')) === productId ?
            newQuantity :
            Number($(item).attr('data-quantity')),
      });
    });
    const itemRequest: CartItemsUpdateRequest = {
      items: items,
      buttonType: 'Cart Modal Quantity Input',
      buttonCopy: 'n/a',
      pageType: pageType,
    };

    changeQuantityModal(itemRequest);
  }, 1000);
});

// Added to cart modal remove product
$(document).on(
  'click',
  '.added-to-cart-modal .remove-from-cart-ajax',
  function (): void {
    const itemId = $(this).attr('data-item-id');
    const pageType = $('body').data('pageType') as string;
    const buttonType = 'icon .remove-from-cart-ajax';
    const buttonCopy = $(this).attr('title') as string;
    const items: Array<{
      itemId: string;
      productId: number;
    }> = [];

    $(`.cart-item-container`).each(function (index, item) {
      items.push({
        itemId: $(item).attr('data-item-id') as string,
        productId: Number($(item).attr('data-product-id')),
      });
    });

    const productId = $(this).attr('data-product-id');
    const productsDetails = JSON.parse($(this).attr('data-product-details') as string) as Array<GoogleAnalyticsProductDetail>;
    window.pushDataLayer({
      event: 'remove_from_cart',
      ecommerce: {
        items: [
          {
            item_id: productId as string,
            quantity: Number(
              $(`input[name=quantity][data-item-id=${itemId}]`).val(),
            ),
          },
        ],
        detail: {
          products: productsDetails,
        },
      },
    });
    removeFromCartModal(itemId as string, pageType, buttonType, buttonCopy);
  },
);

// Product page & results button
$(document).on('click', '.btn-add-to-cart', function (): void {
  const productId = parseInt($(this).data('product-id') as string);
  const price = Number($(this).closest('.product-card')?.data('itemPrice') as string);

  document.dispatchEvent(new CustomEvent<UICartUpdateProductEvent>('cart_add_product', {
    detail: {
      items: [
        {
          id: productId,
          quantity: 1,
        },
      ],
      source: ADDED_FROM_SEARCH_RESULT,
      type: $(this).text(),
    },
  }));
});

// Buy in Set button
$(document).on('click', '#addToCartFromSet', function (): void {
  const productIds = JSON.parse(
    $(this).attr('data-product-ids') as string,
  ) as Array<{
    id?: number,
    price: number,
  }>;
  const eventData: Array<{
    id: number,
    price: number,
    quantity: number,
  }> = [];
  productIds.forEach((item) => {
    if (typeof item.id !== 'undefined') {
      eventData.push({
        id: item.id,
        price: item.price,
        quantity: 1,
      })
    }
  });

  document.dispatchEvent(new CustomEvent<UICartUpdateProductEvent>('cart_add_product', {
    detail: {
      items: eventData,
      source: ADDED_FROM_PRODUCT_VIEW_SET,
      type: 'Product View Set',
    },
  }));
});
